<template>
  <div class="c-container ui-h-full">
    <div class="c-row c-center ui-h-full">
      <div class="ui-relative c-bg-white ui-rounded-md">
        <!-- Content -->
        <div class="ui-absolute ui-top-0 ui-right-0 ui-cursor-pointer ui-p-4">
          <button @click="$modal.hide()">
            <icon
              name="close"
              width="35px"
              height="35px"
            />
          </button>
        </div>

        <div class="c-row ui-h-full ui-p-8">
          <div class="c-col-full sm:c-col-1/2">
            <div class="c-text-b1 ui-mb-3">
              Escaneie este código para pagar
            </div>
            <ol
              class="flex flex-col ui-ml-6"
              style="list-style: auto;"
            >
              <li class="mb-2">
                Acesse seu Internet Banking ou aplicativo de pagamentos;
              </li>
              <li class="mb-2">
                Escolha o pagamento via PIX;
              </li>
              <li> Escaneie o seguinte código: </li>
            </ol>

            <div class="ui-mt-4 c-text-b1">
              Ou copie este código QR para fazer o pagamento:
            </div>
            <input
              type="text"
              class="ui-p-4 ui-mt-6 ui-border ui-rounded qr-code-container"
              readOnly
              :value="pixQrCode"
            >
            <c-button
              class="ui-mt-5"
              @click="() => copyPixQRCode(pixQrCode)"
            >
              Copiar código QR
            </c-button>
          </div>

          <div class="c-col-full sm:c-col-1/2 ui-flex ui-items-center ui-justify-center">
            <img
              :src="pixQrCodeUrl"
              width="300"
              alt="QR Code PIX"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CButton, Icon } from '@estrategiahq/coruja-web-ui';

export default {
  name: 'ImageQrCodeModal',
  components: {
    CButton,
    Icon,
  },
  props: {
    pixQrCode: {
      type: String,
      required: true,
    },
    pixQrCodeUrl: {
      type: String,
      required: true,
    },
    confirmButton: {
      type: Object,
      default: () => ({
        onClick: () => this.$modal.hide(),
        label: 'Fechar',
      }),
    },
  },
  methods: {
    copyPixQRCode (code) {
      navigator.clipboard.writeText(code);
    },
  },
};
</script>
<style scoped>
.qr-code-container {
  width: 22rem;
  height: 3.5rem;
  border-color: #ccc;
  text-overflow: ellipsis;
}
</style>
