<template>
  <c-table class="ui-w-full">
    <template slot="head">
      <c-table-row>
        <c-table-head
          is-sortable
          :direction="sortDirection"
          @change-order="onUpdateOrderBy('dueAt')"
        >
          Data de cobrança
        </c-table-head>
        <c-table-head>Descrição</c-table-head>
        <c-table-head>Valor</c-table-head>
        <c-table-head
          is-sortable
          :direction="sortDirection"
          @change-order="onUpdateOrderBy('status')"
        >
          Status
        </c-table-head>
      </c-table-row>
    </template>
    <c-table-row
      v-for="(recurrence, index) in recurrences"
      :key="index"
    >
      <c-table-data> {{ formatDateBR(recurrence.dueAt) }} </c-table-data>
      <c-table-data> {{ getDescription(recurrence) }} </c-table-data>
      <c-table-data> {{ formatToDecimalCurrency(recurrence.amount) }} </c-table-data>
      <c-table-data class="ui-flex ui-items-center">
        <div>
          <c-icon
            :name="getStatus(recurrence.status).icon"
            :color="getStatus(recurrence.status).iconColor"
            width="24"
          />
        </div>
        <p :class="getStatus(recurrence.status).textColor">
          {{ getStatus(recurrence.status).text }}
        </p>
      </c-table-data>
    </c-table-row>
  </c-table>
</template>

<script>
import {
  CTable,
  CTableRow,
  CTableHead,
  CTableData,
  CIcon,
} from '@estrategiahq/coruja-web-ui';

import { formatToDecimalCurrency } from '@/utils/decimal';
import { formatDateBR, monthIntervalToText } from '@/utils/date';
import { statusRecurrenceOptions } from './status-card-utils';

export default {
  name: 'OrderRecurrence',
  components: { CTable, CTableRow, CTableHead, CTableData, CIcon },
  props: {
    recurrences: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      sortDirection: '',
    };
  },
  methods: {
    formatToDecimalCurrency,
    formatDateBR,
    getStatus(status) {
      return statusRecurrenceOptions[status] ?? statusRecurrenceOptions.default;
    },
    getDescription(recurrence) {
      return `Cobrança recorrente a cada ${monthIntervalToText(
        recurrence.interval,
      )}`;
    },
    onUpdateOrderBy (propName) {
      this.sortDirection = this.sortDirection == 'DESC' ? 'ASC' : 'DESC';

      this.$emit('change-order-by', {
        sort: this.sortDirection,
        prop: propName,
      });
    },
  },
};
</script>
